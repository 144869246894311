import { useEffect } from 'react';
import { GetStaticPaths, GetStaticProps } from 'next';
import {
  SitecoreContext,
  ComponentPropsContext,
  StaticPath,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { handleEditorFastRefresh } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { SitecorePageProps } from 'lib/page-props';
import { sitecorePagePropsFactory } from 'lib/page-props-factory';
import { componentBuilder } from 'temp/componentBuilder';
import { resolveLayout } from 'src/layout-factory';
import { getTargetingValues } from 'lib/utils/middleware-utils';
import sitecoreRedirect from 'lib/seo/redirect/sitecore-redirect';
import { elasticSSRLog } from 'src/opentelemetry/elasticsearch-config';
import { fetchPathsToPreRender } from 'lib/optimize/ssg-page-fetcher';
import { BucketizePathNonEdgeEnv } from 'lib/utils/friendly-url-utils';

const isEdgeConnected =
  process.env.GRAPH_QL_ENDPOINT && new URL(process.env.GRAPH_QL_ENDPOINT).host.startsWith('edge');

const jssRevalidateTimeInSeconds = process.env.JSS_REVALIDATE_TIME_IN_SECONDS
  ? Number(process.env.JSS_REVALIDATE_TIME_IN_SECONDS)
  : 120;

const SitecorePage = ({ notFound, componentProps, layoutData }: SitecorePageProps): JSX.Element => {
  useEffect(() => {
    // Since Sitecore editors do not support Fast Refresh, need to refresh editor chromes after Fast Refresh finished
    handleEditorFastRefresh();
  }, []);

  if (notFound || !layoutData.sitecore.route) {
    // Shouldn't hit this (as long as 'notFound' is being returned below), but just to be safe
    return <></>;
  }

  const isEditing = layoutData.sitecore.context.pageEditing;
  const Layout = resolveLayout(layoutData.sitecore?.route);

  return (
    <ComponentPropsContext value={componentProps}>
      <SitecoreContext
        componentFactory={componentBuilder.getComponentFactory({ isEditing })}
        layoutData={layoutData}
      >
        <Layout layoutData={layoutData} />
      </SitecoreContext>
    </ComponentPropsContext>
  );
};

// This function gets called at build and export time to determine
// pages for SSG ("paths", as tokenized array).
export const getStaticPaths: GetStaticPaths = async () => {
  // Fallback, along with revalidate in getStaticProps (below),
  // enables Incremental Static Regeneration. This allows us to
  // leave certain (or all) paths empty if desired and static pages
  // will be generated on request (development mode in this example).
  // Alternatively, the entire sitemap could be pre-rendered
  // ahead of time (non-development mode in this example).
  // See https://nextjs.org/docs/basic-features/data-fetching/incremental-static-regeneration

  let paths: StaticPath[] = [];
  let fallback: boolean | 'blocking' = 'blocking';

  if (process.env.NODE_ENV !== 'development' && !process.env.DISABLE_SSG_FETCH) {
    try {
      // Note: Next.js runs export in production mode
      paths = await fetchPathsToPreRender();
    } catch (error) {
      console.log('Error occurred while fetching static paths');
      console.log(error);
    }

    fallback = process.env.EXPORT_MODE ? false : fallback;
  }

  return {
    paths,
    fallback,
  };
};

// This function gets called at build time on server-side.
// It may be called again, on a serverless function, if
// revalidation (or fallback) is enabled and a new request comes in.
export const getStaticProps: GetStaticProps = async (context) => {
  let props;
  if (context && context.preview) {
    props = await sitecorePagePropsFactory.create(context);
  } else {
    if (context) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let additionalParameters: any = {};
      const path: string[] = [];
      if (context.params?.path) {
        (context.params?.path as string[]).forEach((part) => {
          if (part && part.includes(';')) {
            additionalParameters = { ...getTargetingValues(part?.split(';').slice(1)) };
            path.push(part?.split(';')[0]);
          } else {
            path.push(part);
          }
        });
      }

      if (context.params) {
        context.params.additionalParameters = additionalParameters;
        context.params.path = path;
      }
    }

    if (context?.params?.path) {
      if (isEdgeConnected) {
        context.params.path = BucketizePath(context.params.path as string[]);
      } else {
        const pathVariants = BucketizePathNonEdgeEnv(context.params.path as string[]);
        for (let i = 0; i < pathVariants.length; i++) {
          context.params.path = pathVariants[i];
          const variantProps = await sitecorePagePropsFactory.create(context);
          if (variantProps.notFound === undefined || i == pathVariants.length - 1) {
            props = variantProps;
            break;
          }
        }
      }
    }

    if (process.env.NEXT_PUBLIC_FORCE_EN && process.env.NEXT_PUBLIC_FORCE_EN === 'true') {
      if (context != null) {
        context.locale = 'en';
      }
    }

    props = await sitecorePagePropsFactory.create(context);

    if (context.params?.path) {
      let sourceUrl = '';
      if (Array.isArray(context.params?.path)) {
        const pathArray = context.params?.path as string[];
        sourceUrl += pathArray.join('/');
      } else {
        sourceUrl += context.params?.path;
      }
      if (sourceUrl == '') {
        sourceUrl = '/';
      }

      const redirect = await sitecoreRedirect(sourceUrl, context.locale ?? 'en');
      if (redirect) props.redirect = redirect;
      elasticSSRLog !== undefined &&
        elasticSSRLog({
          action: 'page-request',
          message: 'page requested',
          url: sourceUrl,
        });
    }

    if (props.redirect) {
      return {
        redirect: props.redirect,
      };
    }
  }

  return {
    props,
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 60 seconds
    revalidate: jssRevalidateTimeInSeconds, // In seconds
    notFound: props.notFound, // Returns custom 404 page with a status code of 404 when true
  };
};

const BucketizePath = (path: string[]): string[] => {
  if (path.length <= 0) {
    return path;
  }

  const firstPart = path[0].toLowerCase();
  if (firstPart == 'products') {
    //handle: /products/XX
    return TryCreateLetterBucketPath(path, 1);
  }

  if (firstPart == 'plugin') {
    //handle: /plugin/XX
    return TryCreateLetterBucketPath(path, 1);
  }

  if (firstPart == 'plugins') {
    //handle: /plugins/XX
    return TryCreateLetterBucketPath(path, 1);
  }

  return path;
};

const TryCreateLetterBucketPath = (path: string[], pathIndexToCheckForBucket: number): string[] => {
  if (path.length - 1 < pathIndexToCheckForBucket) {
    return path;
  }

  const isPartLetterBucket = path[pathIndexToCheckForBucket].length == 1;
  if (isPartLetterBucket) {
    return path;
  }

  const cleanPath = path.map((part) => part.toLowerCase());
  const bucketPath = path[pathIndexToCheckForBucket].charAt(0).toLowerCase();
  let newPath: string[] = [];
  const injectBucketLetterIntoPathQuery = process.env.NEXT_PUBLIC_INJECT_BUCKET_FOLDER == 'true';
  if (injectBucketLetterIntoPathQuery) {
    newPath = [
      ...cleanPath.slice(0, pathIndexToCheckForBucket),
      bucketPath,
      ...cleanPath.slice(pathIndexToCheckForBucket),
    ];
  } else {
    newPath = [...cleanPath];
  }

  return newPath;
};

export default SitecorePage;
